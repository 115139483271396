* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  user-select: auto;
  font-family: Roboto, "Noto Sans KR", "Malgun Gothic", "Nanum Gothic", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 180%;
  border: 0;
  overflow: hidden;
}

#root {
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: white;
}

:root {
  --top-background-blue: #0226d9;
  --top-background-red: #d50000;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

a {
  text-decoration: none;
  color: inherit;
}
